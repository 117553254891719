<template>
  <nav>
    <!--router-link to="/">
      Home
    </router-link> |
    <router-link to="auth">
      About
    </router-link-->
  </nav>

  <router-view />
</template>
<script></script>
